<template>
  <div class="container">
    <!--     <img :src="info.cover" class="bg-img" />
    <div class="content pt-60 prow-30">
      <div class="title">{{ info.name }}</div>

      <div class="timer f-fs-c mt-30">
        <van-icon class="pr-10" size=".28rem" name="clock-o" />
        {{ info.eventStartDate  }}
      </div>

      <Collapse :msg="info.brief" /> -->

    <van-tabs class="active-tab mt-20" v-model="active" title-active-color="#87C6FF" title-inactive-color="#A3ABBF" animated>
      <van-tab v-for="(item, index) in info.timeList" :key="index" :title="item">
        <template v-if="info.activityPhotoListResponseList.findIndex((x) => x.timeSlot === item) !== -1">
          <div class="f-sa-fs items-box">
            <img v-for="pic in info.activityPhotoListResponseList.filter((x) => x.timeSlot === item)" :key="pic.id" class="item-img mt-20" :src="pic.listPreview" />
          </div>
        </template>

        <template v-else>
          <div class="mt-pr-30 f-c-c f-col">
            <img src="~@/assets/empty.png" class="empty" />
            <!-- <img src="https://picsum.photos/200/300" /> -->
            <span class="tip mt-10">暂无数据~</span>
          </div>
        </template>
      </van-tab>
    </van-tabs>

    <!-- <div v-for="(item, index) in info.activityDayListResponseList" :key="index" class="mt-30">
        <div class="item-box mt-10">
          <div class="item-date f-c-c">{{ item.name }}</div>
          <img class="item-img" :src="item.cover" />
        </div>
      </div>
 -->
    <div class="blank"></div>
    <download-app :hidden="$route.query.hidden" />
    <!-- </div> -->
  </div>
  <!--  -->
</template>

<script>
import { getDateItem } from '@/api/activity';
import DownloadApp from '@/components/DownloadApp.vue';

export default {
  name: 'ActivityIndex',

  filters: {
    showTimer: function (time) {
      return time.slice(0, 10);
    },
  },

  components: {
    DownloadApp,
  },

  data: function () {
    return {
      id: '',

      info: {
        cover: '',
        name: '',
        eventStartDate: '',
        brief: '',
        dayList: [
          {
            date: '',
            url: '',
          },
        ],
      },

      active: null,
    };
  },

  computed: {},

  watch: {
    '$route.query': {
      handler(obj) {
        if (obj.id) {
          this.id = obj.id;
        }
      },
      immediate: true,
      deep: true,
    },
    id: {
      handler(str) {
        if (str) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    async loadData() {
      const { data } = await getDateItem({
        id: this.id,
        time: this.$route.query.time,
      });

      // TODO 对接接口
      this.info = data;
    },
  },
};
</script>

<style lang="scss">
.active-tab {
  .van-tabs__line {
    background-color: #87c6ff;
  }
  height: 0.8rem;
  font-size: 0.28rem;
  .van-tabs__wrap {
    height: 0.8rem;
  }
  .van-tab--active .van-tab__text {
    font-size: 0.3rem;
    font-weight: bold;
  }
}
</style>

<style lang="scss" scoped>
.empty {
  width: 50vw;
}
.container {
  width: 100vw;
  background-color: #fff;
  position: relative;
  .bg-img {
    width: 100vw;
  }
  .content {
    box-sizing: border-box;
    position: relative;
    top: -0.4rem;
    width: 100vw;
    min-height: 0.4rem;
    background-color: #fff;
    border-radius: 0.3rem 0.3rem 0 0;
    .title {
      color: #0a1738;
      font-size: 0.4rem;
      font-weight: bold;
    }
    .timer {
      font-size: 0.24rem;
      color: #999;
    }
  }
  .items-box {
    flex-wrap: wrap;
  }
  .item-img {
    width: 26%;
    height: auto;
    border-radius: 0.2rem;
  }

  .mt-pr-30 {
    margin-top: 30vh;
  }
  .tip {
    color: #a3abbf;
    font-size: 0.22rem;
  }

  .blank {
    width: 100%;
    min-height: 0.8rem;
  }
}
</style>
